import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import api from '../../helpers/api';

class AppInfo extends Component {
  constructor() {
    super();
    this.state = { loading: true, loaded: false, info: {}, error: false };

    this.loadInfo = this.loadInfo.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo';
    this.loadInfo();
  }

  loadInfo() {
    const slug = this.props.match.params.slug;
    api.post('/facade/app-info', { slug })
      .then((res) => this.setState({ info: res.data.data, loaded: true }, () => setTimeout(() => this.setState({ loading: false }), 700)))
      .catch((e) => this.setState({ error: 'Something Went Wrong', loading: false }));
  }

  render() {
    const { loading, loaded, error, info } = this.state;
    return (
      <div className="fm-ai">
        {
          error &&
          <div className="fm-ai-error">
            <p className="fm-ai-error-text">{ error }</p>
            <p className="fm-ai-error-instruction">Please go back and try again.</p>
          </div>
        }
        {
          loading &&
          <div className={ loaded ? 'fm-ai-loading fm-ai-loaded' : 'fm-ai-loading' }>
            <span className="fm-ai-loader"></span>
          </div>
        }
        { info.content && <Markdown className="fm-ai-content">{ info.content }</Markdown> }
      </div>
    );
  }
}

export default AppInfo;
