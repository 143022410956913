import React, { Component } from 'react';
import Map, { Polyline } from '../../../common/map';

class WLGParking extends Component {
  render() {
    const mapOptions = { center: { lat: -41.3118, lng: 174.7926 }, zoom: 13 };
    const routeOne = 'vxf{F}_~i`@mAvAq@b@gAd@IIQPq@jAi@rAm@vBs@?SNIJDpACTGLqCl@}@zRWbAq@bBgBvEORITs@rBOViH`G]^Sf@Kv@e@hEIJkJ{B_@FEZQZEVO?cCfTWp@]r@yEhD]DoV_@[_@QLq@GM[a@B_Ak@aD_Ha@c@i@M{Bb@_@?{Aq@sBt@e@QiC_DcDqBw@Mu@Ps@Vk@Ke@c@iA{Ee@YqA_@o@]Q{@IcA@mAQaBa@qA{@oAyAy@iIwGoB[yBE{GmAgAw@u@Q}@Re@n@m@vAo@fAw@Zi@GwFwD]Ig@J_@j@_D|H[^m@Lo@Gq@m@g@Mc@HsC|Cy@h@g@A{Ao@Wi@e@cDMWg@G}@f@gAnAc@R}CIa@[a@w@k@a@g@GmBNe@@cCGcC[mACm@`@Sv@FbAtAnBn@lCdAbApBvGlBzBfEdMXZdDhAhCrBlApITzFQ|CoB~F@bAbDlKNxB';
    const routeTwo = 'nzc{Fixzi`@q@GCf@INFd@QlDk@hDkAWuAFaA`@qAvA_B`C_@^c@B}MgDeOmDMAWfCDlBEvA}@`EuNvk@mAnFe@n@}@hBUx@_@FsAi@]Z_@tA]Ja@r@WAMd@QJMf@XHRZDl@In@GP}AFsA|G_BtHJVRFu@tEaC~K';
    const routeThree = 'lce{Fmw{i`@oD|YCp@eBvNIXm@PmBVQJgAvBS^uAtAKd@Dn@P|@B|@Gh@W^YTa@Ni@@a@Ko@a@u@_@m@?[VQn@[xA_@z@]^mAd@qAhAWr@U~@CrC@vEoFbB{FjAaAVdA|K`BhQf@rGYv@eSfEi@Li@?yAW_@QsDy@{NwCsJqBEWe@SYd@u@j@y@Li@Os@u@W{@IeA@]i@a@qAm@WA_[{NKS@Qq@_@';
    return (
      <div className="fm-wlg-parking">
        <div className="fm-wlg-parking-items">
          <div className="fm-wlg-parking-item">
            <div className="fm-wlg-parking-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/airport-depart.png')` }}></div>
            <p className="fm-wlg-parking-item-title">Departing</p>
            <p className="fm-wlg-parking-item-description">When scootering to Wellington Airport, follow the signs towards Covered parking. As you near the bottom of the entry ramp, keep far right and use the lane marked "Loading Dock". Proceed with care right and then left up a small ramp into the Flamingo parking area.</p>
          </div>
          <div className="fm-wlg-parking-item">
            <div className="fm-wlg-parking-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/airport-arrive.png')` }}></div>
            <p className="fm-wlg-parking-item-title">Arriving</p>
            <p className="fm-wlg-parking-item-description">When scootering from Wellington Airport, head to the ground floor of the terminal. Cross the taxi and pick up area lanes, then turn left and follow signs to the bike parking area. Look for the pink Flamingo parking area to find your scooter.</p>
          </div>
        </div>
        <p className="fm-wlg-parking-description">Riding a Flamingo between the Wellington CBD and Wellington Airport takes approximately 30 minutes. Each scooter costs $1 to unlock, then 65c per minute, which is approximately $20 to/from the airport.</p>
        <div className="fm-wlg-parking-map">
          <Map options={mapOptions} google={window.google}>
            <Polyline encodedPath={routeOne} strokeColor="#FF206E" strokeWeight={4} />
            <Polyline encodedPath={routeTwo} strokeColor="#FF206E" strokeWeight={4} />
            <Polyline encodedPath={routeThree} strokeColor="#FF206E" strokeWeight={4} />
          </Map>
        </div>
      </div>
    );
  }
}

export default WLGParking;
