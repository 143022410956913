import React, { Component } from 'react';
import PromoImage from './components/promo-image';
import PromoDetails from './components/promo-details';
import history from '../../helpers/history';

const promotions = {
  football: {
    title: 'Heading to the game?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero2.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 11 August 2023. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters and bikes in Wellington. This voucher has no monetary value.',
    text: `Get 10 minutes FREE with Flamingo.`,
    description: <>Enter the voucher code "<b>FOOTBALL</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
    download: true,
  },
  palmy: {
    title: 'Need a new ride?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero2.jpg',
    subheading: 'How to Redeem',
    terms: 'Only available to new users. Must redeem the code PINKPN before starting a trip. Only valid for trips on Flamingo scooters in Palmerston North. 1 per customer. Redeem in the Payment tab of the Flamingo app. Subject to availability. Limited time only. This voucher has no monetary value.',
    text: `Get $5 FREE on your first ride with Flamingo Palmy!`,
    description: <>Enter the voucher code "<b>PINKPN</b>" from the Payment section of the Flamingo app for $5 FREE credit!</>,
    download: true,
  },
  'newtown-to-city': {
    title: 'Newtown to City',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'Try the new Newtown to City cycleway with Flamingo',
    terms: 'Promotion ends 31 October 2023. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters and bikes in Wellington. This voucher has no monetary value.',
    text: `Get 20 minutes FREE with Flamingo to try the new cycleway.`,
    description: <>Enter the voucher code "<b>N2C</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  advent: {
    title: 'Advent Calendar',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/promo-advent.jpg',
    subheading: 'How to Play',
    terms: '1 prize per person each calendar day from 1 December 2024 through to 25 December 2024. Only available in Auckland and Dunedin. $1 unlocking fee still applies for free minute vouchers. Minute, unlock and credit vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.',
    text: `Win daily prizes with Flamingo this festive season!`,
    description: <>Tap the pink banner at the top of the Flamingo app to open your Flamingo Advent Calendar. Select todays door to reveal your <b>daily prize of FREE Flamingo credit</b>, but make sure to use it quick, each prize is valid for 24 hours!</>,
    download: true,
  },
  'summer-cycles': {
    title: 'Summer Cycles',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'How to Redeem',
    terms: '1 prize per person each calendar day from 1 January 2023 through to 31 January 2023. $1 unlocking fee still applies. Vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.',
    text: `Get FREE credit daily on Flamingo electric bikes!`,
    description: <>Tap the pink banner at the top of the Flamingo app, then spin the wheel to win free credit each day! But make sure to use it quick, each credit is valid for 24 hours!</>,
    download: true,
  },
  'try-bike': {
    title: 'Try Our Electric Bike',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 31 January 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric bikes in Wellington. This voucher has no monetary value.',
    text: `Get 20 minutes FREE with Flamingo electric bikes.`,
    description: <>Enter the voucher code "<b>TRY20</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  abc: {
    title: 'Workplace E-Bike Training',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'Support Active Transport in your Team',
    text: `Aotearoa Bike Challenge 2024`,
    description: <>As part of the Aotearoa Bike Challenge 2024, Flamingo are holding <b>FREE workplace e-bike training sessions</b>.<br/><br/>Register your workplace for a training session this February in Wellington. Each session includes a safety briefing and the opportunity for everyone on your team to ride a Flamingo e-bike ride. Spaces are limited, so make sure to get in quick!</>,
    button: { url: 'https://docs.google.com/forms/d/e/1FAIpQLScvWQ2w1hTxM55N4LJsmbGvuLpApUEbtgyWG_5oMNopec-ehw/viewform', label: 'Register for a Session' },
  },
  roadsafetyweek: {
    title: 'Road Safety Week',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/road.jpg',
    subheading: 'Practice riding safely this weekend and get 20 mins FREE!',
    text: `Keep our roads safe for everyone in the community.`,
    description: <>In support of Road Safety Week, Flamingo are holding a safety event between 10am and 12pm this Saturday (25 May) on the corner of Cable Street and Oriental Parade. Come down to learn how to safely ride and park our Flamingo scooters. Plus, grab some FREE Flamingo credit and stylish socks to keep you riding in style!</>,
    button: { url: 'https://www.facebook.com/events/1066416071129408', label: 'View Event on Facebook' },
  },
  dunedin: {
    title: 'Kia ora Dunedin',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 14 July 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters in Dunedin. This voucher has no monetary value.',
    text: `Try Flamingo with 20 minutes FREE!`,
    description: <>Enter the voucher code "<b>DUNEDIN20</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  'dunedin-game': {
    title: 'Heading to the Game?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 7 July 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters in Dunedin. This voucher has no monetary value.',
    text: `Take Flamingo with 10 minutes FREE!`,
    description: <>Enter the voucher code "<b>GAME</b>" from the Payment section of the Flamingo app for 10 minutes FREE!<br/>Make sure to check the Flamingo app for parking guidelines at Forsyth Barr Stadium.</>,
    download: true,
  },
  'gardens-magic': {
    title: 'Gardens Magic',
    image: 'https://storage.googleapis.com/flamingo-static/images/parking-area/wn-gardens-magic-content.jpg',
    subheading: 'Free Guided Trips',
    terms: 'Guided trips operate once daily from 7–27 January 2025 (excluding Mondays), departing from Wellington Railway Station at 6:30pm and arriving at the Wellington Botanic Gardens by 6:45pm. Participants must be 18 years or older and have the Flamingo app installed on their mobile device. Please note that availability is limited and trips are offered on a first-come, first-served basis. Return travel is not provided. Riding under the influence of alcohol or drugs is strictly prohibited. Trips may be canceled in the event of adverse weather conditions. For updates on Concert Series postponements, please visit the Wellington City Council website.',
    text: `Arrive in style with Flamingo!`,
    description: <>Celebrate summer with Gardens Magic, where the Wellington Botanic Garden ki Paekākā comes alive with music, lights and fun for all ages! From 7–26 January, enjoy Concert Series, Dazzling Light Displays and a Kids' Garden Trail. <a href="https://wellington.govt.nz/news-and-events/events-and-festivals/gardens-magic">Visit the Wellington City Council website for more information on Gardens Magic</a>.<br/><br/>Flamingo is offering <b>FREE</b> guided trips from Wellington Railway Station to the Botanic Gardens on Concert Series days (Tues–Sun). Meet us at the front of the station at 6:30pm sharp and look for a friendly Flamingo team member to join the ride. Don’t miss out—arrive on time! 🛴✨</>,
    download: true,
  },
  'auckland20': {
    title: 'New Year, New Ride',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero-1.jpg',
    subheading: 'How to Redeem',
    terms: 'This promotion ends at 23:59 on 31 January 2025 and is available exclusively to customers who have not taken a trip with Flamingo in the past calendar year. Limit of one voucher per customer. The voucher must be applied via the Payment screen in the Flamingo app prior to commencing a ride. Once redeemed, the voucher is valid for two weeks. This offer is valid only for trips taken on Flamingo electric scooters within Auckland. Unused credit will be forfeited after the validity period. The voucher holds no monetary value, cannot be exchanged or refunded, and cannot be combined with any other offers. Flamingo reserves the right to modify or terminate this promotion without prior notice.',
    text: `Try Flamingo with $20 FREE!`,
    description: <>Enter the voucher code "<b>AUCKLAND20</b>" from the Payment section of the Flamingo app for $20 FREE credit!<br/><br/>Don't wait—this promotion wraps up on 31 January!</>,
    download: true,
  },
  superscoots: {
    title: 'Super Scoots',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/palmy.jpg',
    subheading: 'Register for Free',
    text: `Free Flamingo scooter tour of CBD hotspots!`,
    description: <>Join Flamingo's free guided tour, Super Scoots, and explore Palmerston North CBD hotspots! Starting at The Square at 2 PM on Sunday, 19 January, the tour runs for approximately 2 hours. Spaces are limited, so register now below!</>,
    button: { url: 'https://www.surveymonkey.com/r/R8ZBJT3', label: 'Register' },
  },
  'perfect-park': {
    title: 'Perfect Park',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/perfect-park.png',
    subheading: 'How to Play',
    terms: 'The promotion ends at 23:59 on 13 February 2025 and is limited to one reward per customer. The game can only be played in an active Flamingo service area. To receive 10 minutes of free Flamingo credit, users must pass all three levels. Credit is valid for two weeks after completing the game and does not cover the $1 unlocking fee required to start a trip. This voucher has no monetary value.',
    text: `Get 10 minutes FREE!`,
    description: <>Think you can park safely? Prove it and score 10 mins free! Tap the pink banner at the top of the Flamingo app to play <b>Perfect Park</b>.</>,
    download: true,
  },
  'auckland30': {
    title: 'New Year, New Ride',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero-2.jpg',
    subheading: 'How to Redeem',
    terms: 'This promotion ends at 23:59 on 31 March 2025. Limit of one voucher per customer. The voucher must be applied via the Payment screen in the Flamingo app prior to commencing a ride. Once redeemed, the voucher is valid for two weeks. This offer is valid only for trips taken on Flamingo electric scooters within Auckland. Unused credit will be forfeited after the validity period. The voucher holds no monetary value, cannot be exchanged or refunded, and cannot be combined with any other offers. Flamingo reserves the right to modify or terminate this promotion without prior notice.',
    text: `Try Flamingo with 30 mins FREE!`,
    description: <>Enter the voucher code "<b>AUCKLAND30</b>" from the Payment section of the Flamingo app for 30 minutes FREE credit!<br/><br/>Don't wait—this promotion wraps up on 31 March!</>,
    download: true,
  },
  '30auckland': {
    title: 'New Year, New Ride',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero-2.jpg',
    subheading: 'How to Redeem',
    terms: 'This promotion ends at 23:59 on 31 March 2025. Limit of one voucher per customer. The voucher must be applied via the Payment screen in the Flamingo app prior to commencing a ride. Once redeemed, the voucher is valid for two weeks. This offer is valid only for trips taken on Flamingo electric scooters within Auckland. Unused credit will be forfeited after the validity period. The voucher holds no monetary value, cannot be exchanged or refunded, and cannot be combined with any other offers. Flamingo reserves the right to modify or terminate this promotion without prior notice.',
    text: `Try Flamingo with 30 mins FREE!`,
    description: <>Enter the voucher code "<b>30AUCKLAND</b>" from the Payment section of the Flamingo app for 30 minutes FREE credit!<br/><br/>Don't wait—this promotion wraps up on 31 March!</>,
    download: true,
  },
  'auckland-students': {
    title: 'Students Ride for Less',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero-2.jpg',
    subheading: 'How to Join',
    terms: 'The Flamingo Student Plan is available exclusively to students enrolled at an accredited higher education institution with a valid student ID. In Auckland, the Student Plan rate is $0 to unlock and 45c per minute. Rates may vary by city. The plan remains valid until the expiry date of the student ID. Applications are processed within 48 hours, and approval will be confirmed via email. Discounted rates apply only after approval.',
    text: `No unlock fees, just 45c per minute!`,
    description: <>To apply, tap <b>"Change Plan"</b> from the Payment screen of the Flamingo app, then select <b>"Student Plan"</b>.</>,
    download: true,
  },
  'auckland-go-pass': {
    title: 'Go Pass',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero-2.jpg',
    subheading: 'How to Join',
    terms: 'Only valid for rides in Auckland. Cannot be used for Group Rides. Subscriptions must be purchased before starting your ride. Standard rates apply after 60 minutes of ride time.',
    text: `60 mins from $2.99 per day!`,
    description: <>The <b>Flamingo Go Pass</b> is the ultimate way to ride more and spend less! Enjoy <b>60 minutes of ride time every day</b> with <b>no unlock fees</b>, giving you the freedom to scoot whenever you like. Choose from flexible plans that fit your lifestyle:<br/><br/><ul><li>🛴 <b>3 Day</b> - $25.00 ($8.33 per day)</li><li>🚀 <b>7 Day</b> - $29.99 ($4.28 per day)</li><li>🔥 <b>30 Day</b> - $89.80 ($2.99 per day)</li></ul><br/>No lock-in, cancel anytime!<br/><br/>Join now – open the <b>Flamingo app</b> and tap "<b>Go Pass</b>"!</>,
    download: true,
  },
  'park2park': {
    title: 'Park2Park',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 31 April 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric bikes in Wellington. This voucher has no monetary value.',
    text: `Get 30 minutes FREE with Flamingo electric bikes.`,
    description: <>Enter the voucher code "<b>PARK2PARK</b>" in the Payment section of the Flamingo app to get 30 minutes FREE on Flamingo bikes. Ride the new cycleway from Island Bay to Waitangi Park!</>,
    download: true,
  },
};

class Promo extends Component {
  constructor(props) {
    super(props);
    this.state = { promo: promotions[props.match.params.id] };
  }

  componentDidMount() {
    if (!this.state.promo) {
      history.push('/');
    } else {
      document.title = `${this.state.promo.title} | Flamingo`;
    }
  }

  render() {
    if (!this.state.promo) {
      return null;
    }

    return (
      <div className="fm-promo">
        <PromoImage promo={this.state.promo} />
        <PromoDetails promo={this.state.promo} />
      </div>
    );
  }
}

export default Promo;
