import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './helpers/history';

import TopBar from './components/common/top-bar';
import Footer from './components/common/footer';
import ScrollToTop from './components/common/scroll-to-top';

import Home from './components/home';
import Ride from './components/ride';
import Contact from './components/contact';
import About from './components/about';
import Cities from './components/cities';
import Safety from './components/safety';
import Terms from './components/terms';
import Privacy from './components/privacy';
import Helmets from './components/helmets';
import Careers from './components/careers';
import City from './components/city';
import Download from './components/download';
import HowTo from './components/how-to';
import Discounts from './components/discounts';
import JoinPlan from './components/join-plan';
import VerifyPlan from './components/verify-plan';
import Quiz from './components/quiz';
import Promo from './components/promo';
import WellingtonAirport from './components/wlg';
import News from './components/news';
import WorkplaceSession from './components/workplace-session';
import AppInfo from './components/app-info';

class App extends Component {
  render() {
    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <div className="fm-app">
          <ScrollToTop />
          <Route path="/" render={({ location }) => !location.pathname.includes('/app-info') && <TopBar />} />
          <Switch>
            <Route path="/ride" component={Ride} />
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
            <Route path="/cities" component={Cities} />
            <Route path="/safety/:location?" component={Safety} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/helmets" component={Helmets} />
            <Route path="/careers" component={Careers} />
            <Redirect path="/students" to="/discounts" />
            <Route path="/discounts" component={Discounts} />
            <Route path="/city/:region" component={City} />
            <Route path="/how-to/:vehicle" component={HowTo} />
            <Route path="/download" component={Download} />
            <Route path="/join-plan/:plan?" component={JoinPlan} />
            <Route path="/verify-plan/:token" component={VerifyPlan} />
            <Route path="/quiz/:question?/:answer?" component={Quiz} />
            <Route path="/promo/:id?" component={Promo} />
            <Route path="/event/:id?" component={Promo} />
            <Route path="/news/:article" component={News} />
            <Route path="/wlg" component={WellingtonAirport} />
            <Route path="/workplace-session" component={WorkplaceSession} />
            <Route path="/app-info/:slug" component={AppInfo} />
            <Route path="/" component={Home} />
          </Switch>
          <Route path="/" render={({ location }) => !location.pathname.includes('/app-info') && <Footer />} />
        </div>
      </Router>
    );
  }
}

export default App;
