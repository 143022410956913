import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toast from '../../../common/toast';
import moment from 'moment';

import api from '../../../../helpers/api';

class WorkplaceSessionForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: '',
      name: '',
      participants: '6-10',
      organisation: '',
      date: '',
      time: 'Afternoon',
      city: 'Wellington',
      type: 'Bikes',
      dates: [],
      error: false,
      success: false,
    };

    this.nameChange = this.nameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.organisationChange = this.organisationChange.bind(this);
    this.cityChange = this.cityChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.participantsChange = this.participantsChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const dates = [];
    const nextSession = moment().add(1, 'week');
    for (var i = 0; i < 40; i++) {
      nextSession.add(1, 'day');
      if ([2, 3, 4, 5].includes(nextSession.day())) {
        dates.push(nextSession.format('dddd, Do MMMM'));
      }
    }
    this.setState({ dates, date: dates[0] });
  }

  nameChange(e) {
    this.setState({ name: e.target.value, error: false });
  }

  emailChange(e) {
    this.setState({ email: e.target.value, error: false });
  }

  organisationChange(e) {
    this.setState({ organisation: e.target.value, error: false });
  }

  cityChange(e) {
    const city = e.target.value;
    let { type } = this.state;
    if (city !== 'Wellington') {
      type = 'Scooters';
    }
    this.setState({ city, type, error: false });
  }

  typeChange(e) {
    this.setState({ type: e.target.value, error: false });
  }

  participantsChange(e) {
    this.setState({ participants: e.target.value, error: false });
  }

  dateChange(e) {
    this.setState({ date: e.target.value, error: false });
  }

  timeChange(e) {
    this.setState({ time: e.target.value, error: false });
  }

  submit(e) {
    e.preventDefault();
    const {
      name,
      email,
      organisation,
      city,
      type,
      participants,
      time,
      date,
    } = this.state;

    this.setState({ loading: true, error: false });
    api.post('/facade/workplace-session', { name, email, organisation, city, type, participants, time, date })
      .then((res) => this.setState({ success: true, loading: false }))
      .catch((e) => this.setState({ error: this.parseApiError(e), loading: false }));
  }

  parseApiError(err) {
    const errorCodes = {
      'validation': 'Please complete all fields.',
      'domain_not_found': 'Email address not applicable',
    };
    return window.access(() => err.response.data.code) && errorCodes[err.response.data.code] ? errorCodes[err.response.data.code] : 'Something went wrong';
  }

  render() {
    const {
      loading,
      dates,
      name,
      email,
      organisation,
      city,
      type,
      participants,
      time,
      date,
      success,
      error,
    } = this.state;

    return (
      <div className="fm-workplace-session-form">
        <p className="fm-workplace-session-form-title">Register Your Workplace</p>
        {
          success ? (
            <p className="fm-workplace-session-form-description">Thank you! We've received your request and will get back to you within 1 business day to confirm your session.</p>
          ) : (
            <>
              <p className="fm-workplace-session-form-description">Fill in the form below and we'll get in touch to plan a Flamingo Workplace Session that works best for your team. Have any questions? <Link to="/contact">Contact us here</Link>.</p>
              <form className="fm-workplace-session-form-form" onSubmit={this.submit}>
                <div className="fm-workplace-session-form-inputs">
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Name</p>
                    <input className="fm-workplace-session-form-input" type="text" disabled={loading} placeholder="Eg. Frank Ringo" onChange={this.nameChange} value={name} />
                  </div>
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Work Email</p>
                    <input className="fm-workplace-session-form-input" type="email" disabled={loading} placeholder="Eg. you@work.com" onChange={this.emailChange} value={email} />
                  </div>
                </div>
                <div className="fm-workplace-session-form-inputs">
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Organisation</p>
                    <input className="fm-workplace-session-form-input" type="text" disabled={loading} placeholder="Eg. Acme Inc" onChange={this.organisationChange} value={organisation} />
                  </div>
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">City</p>
                    <select className="fm-workplace-session-form-input" disabled={loading} onChange={this.cityChange} value={city}>
                      <option value="Auckland">Auckland</option>
                      <option value="Dunedin">Dunedin</option>
                      <option value="Palmerston North">Palmerston North</option>
                      <option value="Porirua">Porirua</option>
                      <option value="Waimakariri">Waimakariri</option>
                      <option value="Wellington">Wellington</option>
                    </select>
                  </div>
                </div>
                <div className="fm-workplace-session-form-inputs">
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Bikes or Scooters</p>
                    <select className="fm-workplace-session-form-input" disabled={loading} onChange={this.typeChange} value={type}>
                      <option value="Both" disabled={ city !== 'Wellington' }>Both (Wellington Only)</option>
                      <option value="Bikes" disabled={ city !== 'Wellington' }>Bikes (Wellington Only)</option>
                      <option value="Scooters">Scooters</option>
                    </select>
                  </div>
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Estimated Participants</p>
                    <select className="fm-workplace-session-form-input" disabled={loading} onChange={this.participantsChange} value={participants}>
                      <option value="1-5">1-5</option>
                      <option value="6-10">6-10</option>
                      <option value="11-15">11-15</option>
                      <option value="16-20">16-20</option>
                      <option value="21-25">21-25</option>
                      <option value="26-30">26-30</option>
                      <option value="30+">30+</option>
                    </select>
                  </div>
                </div>
                <div className="fm-workplace-session-form-inputs">
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Preferred Date</p>
                    <select className="fm-workplace-session-form-input" disabled={loading} onChange={this.dateChange} value={date}>
                      { dates.map((value, i) => <option value={value} key={i}>{value}</option>) }
                    </select>
                  </div>
                  <div className="fm-workplace-session-form-input-container">
                    <p className="fm-workplace-session-form-input-label">Preferred Time of Day</p>
                    <select className="fm-workplace-session-form-input" disabled={loading} onChange={this.timeChange} value={time}>
                      <option value="Morning">Morning</option>
                      <option value="Midday">Midday</option>
                      <option value="Afternoon">Afternoon</option>
                    </select>
                  </div>
                </div>
                <input className="fm-workplace-session-form-button" disabled={loading} type="submit" value={ loading ? 'Loading...' : 'Submit' } />
              </form>
            </>
          )
        }
        { error && <Toast>{error}</Toast> }
        <p className="fm-workplace-session-form-terms">Flamingo Workplace Sessions are subject to availability.</p>
      </div>
    );
  }
}

export default WorkplaceSessionForm;
